// { id: 0, p: { x: center.x, y: center.y }, v:  { x: 0, y: 0 }, m: 1000, r: 20, color: '#c52' }

import Vector from "./vector";

const areColliding = (obj0, obj1) => {
  if (obj0.id === obj1.id) {
    return false;
  }
  const dSquared = (obj1.p.x - obj0.p.x) ** 2 + (obj1.p.y - obj0.p.y) ** 2;
  const d = Math.sqrt(dSquared);
  return (
    d <= 1 + obj0.r + obj1.r
  );
};

const midpoint = (p0, p1) => ({
  x: (p0.x + p1.x) / 2,
  y: (p0.y + p1.y) / 2,
});

// const collisionsExist = (array) => {
//   return array.reduce((acc, cur) => {
//     let collision = array.reduce((a, c) => {
//       return a || areColliding(cur, c);
//     }, false);
    
//     return acc || collision;
//   }, false);
// }

const slightlyBigger = (a, b) => Math.max(a, b) + (Math.min(a, b) / 2);

const combineColors = (c0, c1) => {
  const newColor = `000000${Math.floor(((parseInt(c0.substring(1), 16) + parseInt(c1.substring(1), 16)) / 2)).toString(16)}`;
  return `#${newColor.substring(newColor.length - 6)}`;
};

const combineVelocity = (obj0, obj1) => {
  const m0 = Vector.product(obj0.v, obj0.m);
  const m1 = Vector.product(obj1.v, obj1.m);
  const newMass = obj0.m + obj1.m;
  return Vector.quotient(Vector.sum([m0, m1]), newMass);
};

export const processCollisions = (array) => {
  for (let i = 0, l = array.length; i < l - 1; i++) {
    for (let j = i + 1; j < l; j++) {
      // if they have been processed or they are the same object or they are not colliding
      if (!array[i] || !array[j] || i === j || !areColliding(array[i], array[j])) {
        continue;
      }

      const newBody = {
        id: `${array[i].id}+${array[j].id}`,
        p: midpoint(array[i].p, array[j].p),
        v: combineVelocity(array[i], array[j]),
        m: array[i].m + array[j].m,
        r: slightlyBigger(array[i].r, array[j].r),
        color: combineColors(array[i].color, array[j].color),
      };
      array[i] = newBody;
      array[j] = false;
      console.log(newBody);
    }
  }
  return array.filter(_ => _);
};
