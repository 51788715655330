import { createSlice } from "@reduxjs/toolkit";

const GRAVITATIONAL_CONSTANT = 2;
const MAX_FRAMES = 350; // Infinity; // 1000;

const initialState = {
  gravity: GRAVITATIONAL_CONSTANT,
  maxFrames: MAX_FRAMES,
};

export const parameterSlice = createSlice({
  name: 'parameter',
  initialState,
  reducers: {
    setGravity: (state, action) => {
      state.gravity = action.payload;
    },
    setMaxFrames: (state, action) => {
      state.maxFrames = action.payload;
    },
  },
});

export const { setGravity, setMaxFrames } = parameterSlice.actions;

export default parameterSlice.reducer;
