// import './App.css';
import SvgContainer from './svg';
import Field from './svg/field';
import Controls from './controls';
import { useCallback, useRef, useState } from 'react';
import { calculateFindGravity } from './utils/gravity';
import { useDispatch, useSelector } from 'react-redux';
import { setColor } from './state/slices/bodies';

const d = (n) => Math.floor(Math.random() * n);

const randomColor = () => {
  const options = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
  const n = options.length;
  return [
    '#', options[d(n)], options[d(n)], options[d(n)], options[d(n)], options[d(n)], options[d(n)]
  ].join('');
};

const defaultBody = ({
  id = 69,
  v = 2,
  m = 1,
  r = 10,
  color = '#2c5',
}) => ({
  id, m, r, color,
  v: { x: 0, y: v },
  p: { x: 100, y: 100 },
});

const useDefaultBodies = (defaultValue) => {
  const [d, setD] = useState(defaultValue);
  return [d, () => setD([...defaultValue])];
};  

const useTally = (defaultValue) => {
  const [amount, setAmount] = useState(defaultValue);
  return [amount, () => setAmount(amount + 1)];
};

function App() {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const center = { x: width, y: height };
  const bodyQueue = useRef([]);
  const [bodyCount, incrementBodyCount] = useTally(3);
  const r = useSelector(state => state.bodies.r);
  const m = useSelector(state => state.bodies.m);
  const v = useSelector(state => state.bodies.v);
  const color = useSelector(state => state.bodies.color);
  const dispatch = useDispatch();

  const [defaultBodies, resetDefaultBodies] = useDefaultBodies([
    { id: 0, p: { x: center.x, y: center.y }, v:  { x: 0, y: 0 }, m: 1000, r: 20, color: '#cc5522' },
    { id: 1, p: { x: center.x, y: center.y - 222 }, v:  { x: 3, y: 0 }, m: 7, r: 10, color: '#2255cc' },
    { id: 2, p: { x: center.x, y: center.y - 333 }, v:  { x: -2, y: 0 }, m: 1, r: 10, color: '#5522cc' },
  ]);

  const gravityConstant = useSelector((state) => state.parameter.gravity);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const findGravity = useCallback(calculateFindGravity(gravityConstant), [gravityConstant])

  const addBody = useCallback(() => {
    console.log({v, m, r, color, bodyCount});
    console.log(bodyQueue.current);
    const id = bodyCount;
    const newBody = defaultBody({
      id, v, m, r, color
    });
    console.log(newBody);
    bodyQueue.current.push(newBody);
    incrementBodyCount();
    dispatch(setColor(randomColor()));
  }, [incrementBodyCount, v, m, r, color, bodyCount, dispatch]);

  return (
    <>
      <Controls reset={resetDefaultBodies} addBody={addBody} />
      <SvgContainer width={width} height={height}>
        <Field findGravity={findGravity} defaultBodies={defaultBodies} bodyCount={bodyCount} bodyQueue={bodyQueue} />
      </SvgContainer>
    </>
  );
}

export default App;
