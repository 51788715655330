import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  v:  2,
  m: 1,
  r: 10,
  color: '#22cc55',
};

export const bodiesSlice = createSlice({
  name: 'bodies',
  initialState,
  reducers: {
    setMass: (state, { payload }) => {
      state.m = payload;
    },
    setVelocity: (state, { payload }) => {
      state.v = payload;
    },
    setRadius: (state, { payload }) => {
      state.r = payload;
    },
    setColor: (state, { payload }) => {
      state.color = payload;
    },
  },
});

export const { setMass, setVelocity, setRadius, setColor } = bodiesSlice.actions;

export default bodiesSlice.reducer;
