import { useDispatch, useSelector } from "react-redux";
import { setColor, setMass, setRadius, setVelocity } from "./state/slices/bodies";

const Input = ({ label, value, setValue, type = 'text' }) => (
  <div>
    <label>{label}</label>
    <input
      type={type}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      />
  </div>
);

const NumericInput = ({ label, value, setValue }) => (
  <Input type="number" label={label} value={value} setValue={(v) => setValue(Number(v))} />
);

const Button = ({ onClick, label }) => (
  <div>
    <button onClick={onClick}>{label}</button>
  </div>
); 

const Controls = ({ reset, addBody }) => {
  const dispatch = useDispatch();
  const color = useSelector(state => state.bodies.color);
  const r = useSelector(state => state.bodies.r);
  const m = useSelector(state => state.bodies.m);
  const v = useSelector(state => state.bodies.v);

  return (
    <div className="controls">
      <NumericInput label="m" value={m} setValue={(m) => dispatch(setMass(m))} />
      <NumericInput label="v" value={v} setValue={(v) => dispatch(setVelocity(v))} />
      <NumericInput label="r" value={r} setValue={(r) => dispatch(setRadius(r))} />
      <Input type="color" label="color" value={color} setValue={(c) => dispatch(setColor(c))} />
      <Button onClick={addBody} label="add body" />
    </div>
  );
}

export default Controls;
