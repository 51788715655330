const SvgContainer = ({ width, height, children }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width * 2} ${height * 2}`}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x={0} y={0} width={width * 2} height={height * 2} fill="#222"></rect>
      {children}
    </svg>

  );
};

export default SvgContainer;
