import { useCallback, useEffect, useRef, useState } from "react"
import { processCollisions } from "../utils/collisions";
import { cull } from "../utils/cull";
import Vector from "../utils/vector";

const conditionallyLog = (body, dt, a, v, p) => {
  if (body.id !== 0 && false) {
    console.log(`body ${body.id} (${dt}): a:`, {x: a.x * dt, y: a.y * dt}, 'v:', v, 'p:', p );
  }
};

const useAnimation = (defaultBodies, bodyCount, findGravity, bodyQueue) => {
  const requestRef = useRef();
  const lastTimestamp = useRef(performance.now());
  // use ref for array of bodies, but useState counter to up[date and force rerender]
  // const [ currentFrame, setCurrentFrame ] = useState(0);
  const [ bodies, setBodies ] = useState(defaultBodies);
  
  const step = useCallback((t) => {
    // console.log(bodies[1].p.x); // this does not change on every render
    const dt = (t - lastTimestamp.current) / 10;
    lastTimestamp.current = t;
    setBodies(currentBodies => {
      // console.log(currentBodies[1].p.x) // this changes on every render
      const newBodies = processCollisions(cull([
          ...currentBodies,
          ...currentBodies.length < bodyCount ? bodyQueue.current : []
        ]
        .map((body, i, array) => {
          const a = Vector.sum(array.map((b) => findGravity(body, b).a));
          const v = { x: body.v.x + (a.x * dt), y: body.v.y + (a.y * dt)};
          const p = { x: body.p.x + (v.x * dt), y: body.p.y + (v.y * dt)};
          conditionallyLog(body, dt, a, v, p);
          return { ...body, v, p }
        })));
        
      if (bodyQueue.current.length > 0) {
        // right here, we are inclined
        // to wait to let state update
        // so it has all the current
        // bodies
        bodyQueue.current = [];
      }

      return newBodies;
    });

    requestRef.current = requestAnimationFrame(step);
  }, [bodyCount, bodyQueue, findGravity]);

  useEffect(() => {
    requestRef.current = requestAnimationFrame(step);
    return () => cancelAnimationFrame(requestRef.current);
  }, [bodyCount, findGravity, defaultBodies, step]);

  return bodies;
}

const Field = ({ findGravity, defaultBodies, bodyQueue, bodyCount }) => {

  const bodies = useAnimation(defaultBodies, bodyCount, findGravity, bodyQueue);

  return (
    <g>
      {bodies.map(({ id, p, r, color }) => {
      // console.log(bodies[1].p.x); // this changes on every render
      return (
        <circle key={id} cx={p.x} cy={p.y} r={r} fill={color} />
      )})}
    </g>
  )
}

export default Field;
