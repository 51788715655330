const sum = (args) => args.reduce((acc, cur) => ({ x: acc.x + cur.x, y: acc.y + cur.y }), { x: 0, y: 0 });

const product = ({x, y}, s) => ({
  x: x * s,
  y: y * s
});

const quotient = ({x, y}, s) => ({
  x: x / s,
  y: y / s
});

const Vector = {
  sum,
  new: (x, y) => ({ x, y }),
  product,
  quotient,
};

export default Vector;
