export const calculateFindGravity = (GRAVITATIONAL_CONSTANT) => (obj0, obj1) => {
  if (obj0.id === obj1.id) {
    return (
      { a: { x: 0, y: 0 }, f: 0 }
    );
  }
  const dSquared = (obj1.p.x - obj0.p.x) ** 2 + (obj1.p.y - obj0.p.y) ** 2;
  const d = Math.sqrt(dSquared);
  const direction = { x: obj1.p.x - obj0.p.x, y: obj1.p.y - obj0.p.y }
  const normal = { x: direction.x / d, y: direction.y / d };

  const f = (GRAVITATIONAL_CONSTANT * obj0.m * obj1.m) / (dSquared);
  const a = f / obj0.m;
  if (obj0.id !== 0) {
    // console.log(obj0.v, a, f);
  }
  return { a: { x: normal.x * a, y: normal.y * a }, f };
};
