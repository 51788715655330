import { configureStore } from '@reduxjs/toolkit';
import bodiesReducer from './slices/bodies';
import parameterReducer from './slices/parameter';

export const store = configureStore({
  reducer: {
    parameter: parameterReducer,
    bodies: bodiesReducer,
  }
})
